import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import SeoComp from "../../../components/SeoComp"
import MatelasImageSlider from "../../../components/Products/MatelasImageSlider"
import Testimonials from "../../../components/Layout/Testimonials"
import Footer from "../../../components/Layout/Footer"
import { withStoreContext } from "../../../context/StoreContext"

import {
  ProductInfoSection,
  Container,
  ProductInfoBox,
  ProductImageSection,
  Select,
  ErrorBox,
  ExclusifAddToCart,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  MatelasComboWrapper,
  MatelasComboBoxImage,
  LyneComboBox,
  ExclusifComboBoxTag,
  ExclusifComboBoxTitle,
  LyneCheckImage,
  LyneComboLeftSection,
  LyneComboBottomSection,
  ExclusifProductDiscountPrice,
  ExclusifProductStrikePrice,
  DimensionText,
  EcoParticipationText,
  AddToCartWrapper,
  LyneComboBoxTagWrapper,
} from "../../../components/Products/styled"
import MatelasProductTopSection from "../../../components/Products/MatelasProductTopSection"
import MatelasTestimonialSection from "../../../components/Products/MatelasTestimonialSection"
import LyneupPageSectionList from "../../../components/Products/LyneupPageSectionList"
import SoulagementSection from "../../../components/Products/SoulagementSection"
import SoulagerSection from "../../../components/Products/SoulagerSection"
import ConfortSection from "../../../components/Products/ConfortSection"
import ProductSainSection from "../../../components/Products/ProductSainSection"
import CompositionSection from "../../../components/Products/CompositionSection"
import SatisfactionClientSection from "../../../components/Products/SatisfactionClientSection"
import MatelasReviewSection from "../../../components/Products/MatelasReviewSection"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import MatelasAlmaSection from "../../../components/Products/MatelasAlmaSection"
import ExclusifStickyAddToCart from "../../../components/Products/ExclusifStickyAddToCart"
import MatelasMiniCart from "../../../components/MatelasMiniCart"
import { getPrice, navigateToCart } from "../../../utils/navigateToCart"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../context/ApolloContext"
import {
  addToCartScript,
  productGtmEvent,
} from "../../../utils/additionalScriptHelpers"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
const $ = isBrowser ? require("jquery") : {}

const matelas = ({
  intl,
  data,
  data: { allShopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  const [selectedVariant, setselectedVariant] = useState(null)
  const [singleMattressVariant, setsingleMattressVariant] = useState(null)
  const [packMattressVariant, setpackMattressVariant] = useState(null)
  const [singleMattressData, setsingleMattressData] = useState(null)
  const [packMattressData, setpackMattressData] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({})
  const [selectProductType, setselectProductType] = useState("Seul")
  const [productPrice, setproductPrice] = useState(649)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [activeFlag, setactiveFlag] = useState(false)
  const [ecoParticipationPrice, setecoParticipationPrice] = useState("")
  const [showMiniCart, setshowMiniCart] = useState(false)

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sizeParam = urlParams.get("Size")
    const offerParam = urlParams.get("Offer")
    let options = { ...selectOptions }
    let product = ""

    let singleMattress = data.allShopifyProduct?.edges.find(
      item => item.node.handle === "matelas"
    )?.node
    let packMattress = data.allShopifyProduct?.edges.find(
      item => item.node.handle === "1-matelas-oreiller"
    )?.node

    if (offerParam === "Pack") {
      setselectProductType("Pack")
      product = packMattress
      ecoParticipation("Pack", sizeParam)
    } else {
      setselectProductType("Seul")
      product = singleMattress
      ecoParticipation("Seul", sizeParam)
    }

    if (sizeParam) {
      options.Size = sizeParam
    } else {
      options.Size = "80x200cm"
      let paramString = ""
      paramString = paramString + "?Size=" + options.Size
      paramString = paramString + "&Offer=Seul"
      isBrowser && history.replaceState(null, null, paramString)
    }

    if (Object.keys(options).length === 1) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      const singleMattressVariant =
        singleMattress?.variants.length > 0 &&
        client.product.helpers.variantForOptions(singleMattress, options)

      const packMattressVariant =
        packMattress?.variants.length > 0 &&
        client.product.helpers.variantForOptions(packMattress, options)

      let productDiscountPrice = selectedVariant.price
      if (offerParam === "Pack") {
        productDiscountPrice = groupDiscountPrice()
      } else {
        productDiscountPrice = discountPrice()
      }

      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )

      setselectedVariant(selectedVariant)
      setsingleMattressVariant(singleMattressVariant)
      setpackMattressVariant(packMattressVariant)
      setproductPrice(selectedVariant.price)
    }

    setselectOptions(options)
    setsingleMattressData(singleMattress)
    setpackMattressData(packMattress)
  }

  const handleParamStrings = selectedOptions => {
    let paramString = ""

    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Size") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Size") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })

    paramString = paramString + "&Offer=" + selectProductType

    isBrowser && history.replaceState(null, null, paramString)
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    let selectedOptions = { ...selectOptions }
    let product = ""

    if (target.name === "Size") {
      selectedOptions[target.name] = target.value
    } else {
      if (target.value) selectedOptions[target.name] = target.value
    }
    handleParamStrings(selectedOptions)
    setselectOptions(selectedOptions)

    if (selectProductType === "Pack") {
      setselectProductType("Pack")
      product = packMattressData
      ecoParticipation("Pack", selectedOptions["Size"])
    } else {
      setselectProductType("Seul")
      product = singleMattressData
      ecoParticipation("Seul", selectedOptions["Size"])
    }

    if (Object.keys(selectedOptions).length === 1) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        selectedOptions
      )
      const singleMattressVariant = client.product.helpers.variantForOptions(
        singleMattressData,
        selectedOptions
      )
      const packMattressVariant = client.product.helpers.variantForOptions(
        packMattressData,
        selectedOptions
      )

      let productDiscountPrice = selectedVariant.price
      if (selectProductType === "Pack") {
        productDiscountPrice = groupDiscountPrice()
      } else {
        productDiscountPrice = discountPrice()
      }

      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
      setselectedVariant(selectedVariant)
      setsingleMattressVariant(singleMattressVariant)
      setpackMattressVariant(packMattressVariant)
    }
  }

  const removeErros = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error_lynefit",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element ===
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" }),
        ]
    }

    removeErros(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = async () => {
    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        null,
        checkout => {
          addToCartScript(
            selectProductType === "Seul"
              ? singleMattressData.id
              : packMattressData.id,
            selectProductType === "Seul"
              ? singleMattressData.title
              : packMattressData.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          if (
            selectOptions["Size"] != "90x190cm" &&
            selectOptions["Size"] != "140x190cm" &&
            selectOptions["Size"] != "160x200cm" &&
            selectOptions["Size"] != "180x200cm"
          ) {
            navigateToCart(intl.locale)
          } else {
            setshowMiniCart(true)
          }
        }
      )
    } else {
      handleErrors()
    }
  }

  const handleProductType = productType => {
    let price = ""
    let product = ""
    let selectedOptions = { ...selectOptions }

    setselectProductType(productType)

    if (productType === "Pack") {
      price = groupDiscountPrice()
      product = packMattressData
    } else {
      price = discountPrice()
      product = singleMattressData
    }

    ecoParticipation(productType, selectedOptions["Size"])

    let paramString = ""
    paramString = paramString + "?Size=" + selectedOptions["Size"]
    paramString = paramString + "&Offer=" + productType
    isBrowser && history.replaceState(null, null, paramString)

    setproductPrice(price)

    if (Object.keys(selectedOptions).length === 1) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        selectedOptions
      )
      const singleMattressVariant = client.product.helpers.variantForOptions(
        singleMattressData,
        selectedOptions
      )
      const packMattressVariant = client.product.helpers.variantForOptions(
        packMattressData,
        selectedOptions
      )
      setselectedVariant(selectedVariant)
      setsingleMattressVariant(singleMattressVariant)
      setpackMattressVariant(packMattressVariant)
    }
  }

  let discountPercentage = 0.3

  const discountPrice = () => {
    let discountPrice = Number(
      singleMattressVariant
        ? singleMattressVariant.price
        : singleMattressData?.priceRangeV2?.maxVariantPrice.amount
    )

    discountPrice =
      Number(discountPrice) - Number(discountPrice) * Number(discountPercentage)

    return getPrice(discountPrice)
  }

  const groupPrice = () => {
    let groupPrice = Number(
      packMattressVariant
        ? packMattressVariant.price
        : packMattressData?.priceRangeV2?.maxVariantPrice.amount
    )

    return getPrice(groupPrice)
  }

  const groupDiscountPrice = () => {
    let groupDiscountPrice = groupPrice()

    groupDiscountPrice =
      Number(groupDiscountPrice) -
      Number(groupDiscountPrice) * Number(discountPercentage)

    return getPrice(groupDiscountPrice)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const ecoParticipation = (type, size) => {
    if (type === "Pack") {
      if (size == "180x200cm" || size == "160x200cm") {
        setecoParticipationPrice("15,40€")
      } else if (size == "140x200cm" || size == "140x190cm") {
        setecoParticipationPrice("11,40€")
      } else {
        setecoParticipationPrice("5,70€")
      }
    } else {
      if (size == "180x200cm" || size == "160x200cm") {
        setecoParticipationPrice("15,00€")
      } else if (size == "140x200cm" || size == "140x190cm") {
        setecoParticipationPrice("11,00€")
      } else {
        setecoParticipationPrice("5,50€")
      }
    }
  }

  const mounted = useRef()
  var searchLocation = useLocation()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      BottomBarActive()
    }
  }, [selectedVariant, selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  useEffect(() => {
    let price = ""
    if (selectProductType === "Pack") {
      price = groupDiscountPrice()
    } else {
      price = discountPrice()
    }
    setproductPrice(price)
  }, [selectedVariant])

  useEffect(() => {
    if (intl.locale != "fr") navigate(`/${intl.locale}/shop/`)
  }, [intl.locale])

  return (
    process.env &&
    (process.env.GATSBY_ACTIVE_ENV === "eur" ||
      process.env.GATSBY_ACTIVE_ENV === "general") &&
    intl.locale === "fr" &&
    data && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="matelas"
          productPrice={productPrice}
          hreflangPathname={{
            fr: "shop/matelas",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />

        <ExclusifStickyAddToCart
          data={
            data.allDirectusMatelasTranslation.nodes[0].exclusif_combo_section
          }
          productType={selectProductType}
          size={selectOptions["Size"]}
          cart_text={
            !selectOptions["Size"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_size",
                })
              : data.allDirectusMatelasTranslation.nodes[0].add_to_cart_button
          }
          discountPrice={
            selectProductType === "Seul"
              ? discountPrice()
              : groupDiscountPrice()
          }
          regularPrice={
            selectProductType === "Seul"
              ? Number(
                  singleMattressVariant
                    ? singleMattressVariant.price
                    : singleMattressData?.priceRangeV2?.maxVariantPrice.amount
                )
              : groupPrice()
          }
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          id="sticy-button"
          page="enplus"
        />

        <MatelasMiniCart
          data={
            data.allDirectusMatelasTranslation.nodes[0].enplus_mini_cart_section
          }
          price={data.allShopifyProduct.edges}
          product={selectedVariant}
          offerType={selectProductType}
          size={selectOptions["Size"]}
          discountPrice={
            selectProductType === "Seul"
              ? discountPrice()
              : groupDiscountPrice()
          }
          regularPrice={
            selectProductType === "Seul"
              ? Number(
                  singleMattressVariant
                    ? singleMattressVariant.price
                    : singleMattressData?.priceRangeV2?.maxVariantPrice.amount
                )
              : groupPrice()
          }
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          show={showMiniCart}
          hide={setshowMiniCart}
          page={"matelas-enplus"}
        />

        <ProductInfoSection id="info-section" page="lyne_home">
          <Container>
            <ProductInfoBox>
              <ProductImageSection>
                <MatelasImageSlider
                  data={
                    data.allDirectusMatelasTranslation.nodes[0].banner_slider
                      .list
                  }
                  page="matelas"
                />
              </ProductImageSection>

              <ProductRightInfoSection>
                <MatelasProductTopSection
                  title={data.allDirectusMatelasTranslation.nodes[0].title}
                  subtitle={
                    data.allDirectusMatelasTranslation.nodes[0].subtitle
                  }
                  description={
                    data.allDirectusMatelasTranslation.nodes[0].description
                  }
                  review={true}
                  page="matelas"
                />
                <DimensionText
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusMatelasTranslation.nodes[0]
                        .dimensions_text,
                  }}
                />
                <Select
                  id="pa_taille_classique"
                  name="Size"
                  pageName="matelas"
                  onChange={handleChangeSelectOptions}
                  value={selectOptions["Size"]}
                  error={!selectOptions["Size"] && Errors.exist}
                >
                  {/* <option value="">
                    {intl.formatMessage({
                      id: "my_percko_matelas_size_text",
                    })}
                  </option> */}
                  {data.allDirectusMatelasTranslation.nodes[0].product_size.list.map(
                    (item, index) => (
                      <option
                        value={item.value}
                        key={`Size-${index.toString()}`}
                      >
                        {item.size}
                      </option>
                    )
                  )}
                </Select>
                <MatelasComboWrapper>
                  <LyneComboBox onClick={() => handleProductType("Seul")}>
                    <LyneComboBoxTagWrapper>
                      <ExclusifComboBoxTag
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusMatelasTranslation.nodes[0]
                              .exclusif_combo_section.offer_title,
                        }}
                        page="enplus"
                      />
                    </LyneComboBoxTagWrapper>
                    <ExclusifComboBoxTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusMatelasTranslation.nodes[0]
                            .exclusif_combo_section.single_product_text,
                      }}
                      page="enplus"
                    />
                    <LyneComboBottomSection>
                      <LyneComboLeftSection>
                        <MatelasComboBoxImage
                          className="lazyload"
                          data-src="https://static.percko.com/uploads/8dd8f7f6-0a70-4bb1-9728-f29c0f0c1f20.jpg"
                        />
                        <ExclusifProductStrikePrice page="enplus">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            singleMattressVariant
                              ? singleMattressVariant.price
                              : singleMattressData?.priceRangeV2
                                  ?.maxVariantPrice.amount
                          )}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ExclusifProductStrikePrice>
                        <ExclusifProductDiscountPrice page="enplus">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${discountPrice()}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ExclusifProductDiscountPrice>
                      </LyneComboLeftSection>
                      <LyneCheckImage
                        src={
                          selectProductType === "Seul"
                            ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                            : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                        }
                      />
                    </LyneComboBottomSection>
                  </LyneComboBox>
                  <LyneComboBox
                    border="0px"
                    onClick={() => handleProductType("Pack")}
                  >
                    <LyneComboBoxTagWrapper>
                      <ExclusifComboBoxTag
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusMatelasTranslation.nodes[0]
                              .exclusif_combo_section.offer_title,
                        }}
                        page="enplus"
                      />
                    </LyneComboBoxTagWrapper>
                    {selectedVariant && selectedVariant.price > 900 ? (
                      <ExclusifComboBoxTitle
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusMatelasTranslation.nodes[0]
                              .exclusif_combo_section.offer_second_text,
                        }}
                        page="enplus"
                      />
                    ) : (
                      <ExclusifComboBoxTitle
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusMatelasTranslation.nodes[0]
                              .exclusif_combo_section.offer_text,
                        }}
                        page="enplus"
                      />
                    )}
                    <LyneComboBottomSection>
                      <LyneComboLeftSection>
                        {selectedVariant && selectedVariant.price > 900 ? (
                          <MatelasComboBoxImage src="https://static.percko.com/uploads/aa0c7a01-ea22-4200-ac3a-73be7e9125d1.jpg" />
                        ) : (
                          <MatelasComboBoxImage src="https://static.percko.com/uploads/061b5af6-1561-4e85-ba3d-a936bf59fb75.jpg" />
                        )}
                        <ExclusifProductStrikePrice page="enplus">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${groupPrice()}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ExclusifProductStrikePrice>
                        <ExclusifProductDiscountPrice page="enplus">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${groupDiscountPrice()}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ExclusifProductDiscountPrice>
                      </LyneComboLeftSection>
                      <LyneCheckImage
                        src={
                          selectProductType === "Pack"
                            ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                            : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                        }
                      />
                    </LyneComboBottomSection>
                  </LyneComboBox>
                </MatelasComboWrapper>
                {Errors && Errors.exist && (
                  <ErrorBox>
                    {Errors.message.map((item, index) => (
                      <span key={index.toString()}>{item}</span>
                    ))}
                  </ErrorBox>
                )}
                <div style={{ margin: "-10px 0px 10px 0px" }}>
                  {productPrice && selectedVariant && (
                    <MatelasAlmaSection
                      price={
                        selectProductType === "Seul"
                          ? discountPrice()
                          : groupDiscountPrice()
                      }
                    />
                  )}
                </div>
                <AddToCartWrapper>
                  <ExclusifAddToCart
                    id="add_to_cart"
                    loading={storeContext.adding ? "adding" : ""}
                    onClick={selectOptions["Size"] ? handleAddTocart : () => {}}
                    disabled={selectOptions["Size"] ? false : true}
                    page="enplus"
                  >
                    {
                      data.allDirectusMatelasTranslation.nodes[0]
                        .add_to_cart_button
                    }
                  </ExclusifAddToCart>
                </AddToCartWrapper>
                {selectOptions["Size"] && (
                  <ProductEstimateShippingBox page="matelas">
                    <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                    <ProductEstimateShippingText>
                      {cartEstimateDeliveryText(
                        "matelas",
                        data.allDirectusCommonTranslation.nodes[0].months_text,
                        intl.locale,
                        selectOptions["Size"]
                      )}
                    </ProductEstimateShippingText>
                  </ProductEstimateShippingBox>
                )}
                {ecoParticipationPrice && (
                  <EcoParticipationText
                    dangerouslySetInnerHTML={{
                      __html: intl
                        .formatMessage({
                          id: "eco_paricipation_text",
                        })
                        .replace("eco_price", ecoParticipationPrice),
                    }}
                  />
                )}
              </ProductRightInfoSection>
            </ProductInfoBox>
          </Container>
        </ProductInfoSection>

        {data.allDirectusMatelasTranslation.nodes[0].testimonial_section && (
          <MatelasTestimonialSection
            data={
              data.allDirectusMatelasTranslation.nodes[0].testimonial_section
            }
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0].page_section_list && (
          <LyneupPageSectionList
            data={data.allDirectusMatelasTranslation.nodes[0].page_section_list}
            page="matelas"
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0].soulagment_section && (
          <SoulagementSection
            data={
              data.allDirectusMatelasTranslation.nodes[0].soulagment_section
            }
            id="soulager_dos_section"
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0].soulager_section && (
          <SoulagerSection
            data={data.allDirectusMatelasTranslation.nodes[0].soulager_section}
            id="soul_section"
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0]
          .confort_optimal_section && (
          <ConfortSection
            data={
              data.allDirectusMatelasTranslation.nodes[0]
                .confort_optimal_section
            }
            id="confort_optimal_section"
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0].produit_sain_section && (
          <ProductSainSection
            data={
              data.allDirectusMatelasTranslation.nodes[0].produit_sain_section
            }
            id="product_sain_section"
            page="matelas"
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0].composition_section && (
          <CompositionSection
            data={
              data.allDirectusMatelasTranslation.nodes[0].composition_section
            }
            id="composition_section"
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0].satisfaction_section && (
          <SatisfactionClientSection
            data={
              data.allDirectusMatelasTranslation.nodes[0].satisfaction_section
            }
            id="satisfaction_client"
          />
        )}

        {data.allDirectusMatelasTranslation.nodes[0].review_section && (
          <MatelasReviewSection
            data={data.allDirectusMatelasTranslation.nodes[0].review_section}
            id="avis_section"
            page="matelas"
          />
        )}

        {/* {data.allDirectusMatelasTranslation.nodes[0].cross_section && (
          <CrossProductSection
            data={data.allDirectusMatelasTranslation.nodes[0].cross_section}
            price={data.allShopifyProduct.edges}
          />
        )} */}

        {data.allDirectusMatelasTranslation.nodes[0].product_faq_section && (
          <ProductFaqSection
            data={
              data.allDirectusMatelasTranslation.nodes[0].product_faq_section
            }
            pageName="matelas"
            id="faq_section"
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const ProductRightInfoSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 5;
  padding-left: 10px;
  justify-content: space-between;
  padding: 20px 0px 20px 10px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusMatelasTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        banner_slider {
          list {
            image
            alt
          }
        }
        title
        subtitle
        description
        add_to_cart_button
        dimensions_text
        product_size {
          list {
            size
            value
          }
        }
        combo_section {
          single_product_text
          offer_title
          offer_text
          offer_second_text
        }
        exclusif_combo_section {
          single_product_text
          offer_title
          offer_text
          offer_second_text
        }
        testimonial_section {
          list {
            image
            alt
            text
            mobile_text
            popup_text
          }
        }
        page_section_list {
          list {
            title
            id
          }
        }
        soulagment_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          list {
            image
            alt
            title
            subtitle
            icon_image
            author_name
            designation
            text
          }
        }
        soulager_section {
          image
          alt
          title
          subtitle
          icon_image
          author_name
          designation
          text
        }
        confort_optimal_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          top_list {
            image
            alt
            text
            mobile_text
          }
          bottom_list {
            image
            alt
            title
            subtitle
            list {
              image
              alt
              text
              popup_text
            }
          }
        }
        produit_sain_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          bottom_text
          mobile_bottom_text
          list {
            image
            alt
            top_image
            title
            text
            mobile_text
          }
        }
        composition_section {
          section_title
          image
          alt
          list {
            title
            text
          }
        }
        satisfaction_section {
          title
          subtitle
          mobile_subtitle
        }
        review_section {
          title
          text
          review_list {
            image
            alt
            quote_image
            name
            designation
            company
            city
            text
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              alt
              text
            }
            open
          }
        }
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          url
          button_text
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
        alma_popup_text
        mini_cart_section {
          title
          cart_button_text
          cart_button_url
          offer_section_title
          offer_top_text
          offer_title
          offer_title_url
          offer_text
          offer_image
          offer_image_alt
          offer_bottom_text
          product_list {
            name
            image
            alt
            title
            text
            top_text
            button_text
            page_text
            url
          }
        }
        enplus_mini_cart_section {
          title
          cart_button_text
          cart_button_url
          offer_section_title
          offer_top_text
          offer_title
          offer_title_url
          offer_text
          offer_image
          offer_image_alt
          offer_bottom_text
          product_list {
            name
            image
            alt
            title
            text
            top_text
            button_text
            page_text
            url
          }
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          id: storefrontId
          handle
          createdAt
          updatedAt
          descriptionHtml
          description
          productType
          title
          vendor
          publishedAt
          options {
            id
            name
            values
          }
          variants {
            id: storefrontId
            title
            price
            weight
            sku
            image {
              id
              src: originalSrc
              #   altText
            }
            selectedOptions {
              name
              value
            }
            price
          }
          images {
            src: originalSrc
            id
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        months_text {
          month
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "matelas_exclusif" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default withStoreContext(injectIntl(matelas))
